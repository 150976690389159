<template>
  <div @click="$router.go(-1)" class="p-mb-4" style="width: fit-content; cursor: pointer;">
    <i class="pi pi-arrow-left" style="display: inline-block; padding-right: 4px;"></i><h5 style="display: inline-block;"> Regresar</h5>
  </div>
  <div>
    <Card style="width: auto; margin-bottom: 2em">
      <template #title>
        <div style="display: flex;">
          <div style="flex: 1 1 0;">
            Crear Restaurante
          </div>
          <!--          <div style="flex: 1 1 0; text-align: right; margin-right: 4px;">Verificado: <span :style="restaurant.verified ? 'color: #22C55E' : 'color: #EF4444'">{{ restaurant.verified ? 'Si' : 'No'}}</span></div>-->
        </div>
      </template>
      <template #content>
        <form @submit.prevent="createRestaurant()">
          <h4 class="p-text-center p-text-bold">Información Básica</h4>
          <div style="display: flex; justify-content: center;">
            <div style="flex: 1 1 auto; margin-right: 25px;">
              <div style="display:block; margin: 0 0 0 auto; width: fit-content;">
                <div style="">
                  <h5>Nombre de Restaurante <span style="color: #ea1b1b">*</span></h5>
                  <InputText id="name" type="text" v-model="body.name" style="width: 20rem;" required/>
                </div>
                <div class="p-mt-4">
                  <h5>Email <span style="color: #ea1b1b">*</span></h5>
                  <InputText type="email" v-model="body.email" style="width: 20rem;" required/>
                </div>
                <div class="p-mt-4">
                  <h5>Instagram Username <span style="color: #ea1b1b">*</span></h5>
                  <InputText type="text" v-model="body.ig_username" style="width: 20rem;" required/>
                </div>
                <div class="p-mt-4">
                  <h5>Biografía</h5>
                  <InputText type="text" v-model="body.biography" style="width: 20rem;" required/>
                </div>
              </div>
            </div>

            <div style="flex: 1 1 auto;">
              <div>
                <h5>Nombre de Contacto <span style="color: #ea1b1b">*</span></h5>
                <InputText id="name" type="text" v-model="body.contact_name" style="width: 20rem;" required/>
              </div>
              <div>
                <h5 class="p-mt-4">Teléfono <span style="color: #ea1b1b">*</span></h5>
                <InputText type="text" v-model="body.phone" style="width: 20rem;" required/>
              </div>
              <div>
                <h5 class="p-mt-4">Contraseña <span style="color: #ea1b1b">*</span></h5>
                <InputText type="text" v-model="body.password" style="width: 20rem;" required/>
              </div>
            </div>
          </div>

          <h4 class="p-text-center p-text-bold p-mt-6">Dirección</h4>
          <div style="display: flex; justify-content: center;">
            <div style="flex: 1 1 auto; margin-right: 25px;">
              <div style="display:block; margin: 0 0 0 auto; width: fit-content;">
                <div class="">
                  <h5>Dirección</h5>
                  <InputText type="text" v-model="body.address" style="width: 20rem;"/>
                </div>
                <div class="p-mt-4">
                  <h5>Ciudad</h5>
                  <InputText type="text" v-model="body.city" style="width: 20rem;"/>
                </div>
              </div>
            </div>

            <div style="flex: 1 1 auto;">
              <div class="">
                <h5>Estado/Provincia</h5>
                <InputText type="text" v-model="body.state" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h5>Zip Code</h5>
                <InputText type="text" v-model="body.zip_code" style="width: 20rem;"/>
              </div>
            </div>
          </div>

          <h4 class="p-text-center p-text-bold p-mt-6">Datos de facturación</h4>
          <div style="display: flex; justify-content: center;">
            <div style="flex: 1 1 auto; margin-right: 25px;">
              <div style="display:block; margin: 0 0 0 auto; width: fit-content;">
                <div class="">
                  <h5>Razón social <span style="color: #ea1b1b">*</span></h5>
                  <InputText type="text" v-model="body.billing_address.business_name" style="width: 20rem;"/>
                </div>
                <div class="p-mt-4">
                  <h5>Dirección de facturación <span style="color: #ea1b1b">*</span></h5>
                  <InputText type="text" v-model="body.billing_address.address_line" style="width: 20rem;"/>
                </div>
                <div class="p-mt-4">
                  <h5>Ciudad <span style="color: #ea1b1b">*</span></h5>
                  <InputText type="text" v-model="body.billing_address.city" style="width: 20rem;"/>
                </div>
              </div>
            </div>

            <div style="flex: 1 1 auto;">
              <div class="">
                <h5>Estado/Provincia <span style="color: #ea1b1b">*</span></h5>
                <InputText type="text" v-model="body.billing_address.state" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h5>Zip Code <span style="color: #ea1b1b">*</span></h5>
                <InputText type="number" v-model="body.billing_address.zip_code" style="width: 20rem;"/>
              </div>
              <div class="p-mt-4">
                <h5>CIF <span style="color: #ea1b1b">*</span></h5>
                <InputText type="text" v-model="body.billing_address.cif" style="width: 20rem;"/>
              </div>
            </div>
          </div>

          <Button
              type="submit"
              style="display: block; margin: 0 auto;"
              class="p-mt-6">Crear restaurante</Button>
        </form>
      </template>
    </Card>
  </div>
  <Toast />
</template>
<script>
export default {
  name: 'create',
  data () {
    return {
      restaurant: [],
      body: {
        name: '',
        biography: '',
        billing_address: {
          business_name: ''
        }
      },
      ig_username: '',
      email: '',
      errors: []
    }
  },
  methods: {
    createRestaurant () {
      this.$repository.restaurants
        .create(this.body)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: `Se ha creado el restaurante`,
            life: 1000
          });
          this.$router.push({ name: 'restaurants.index' })
        })
        .catch((err) => {
          const summary = err.response.data?.message
          const detail = err.response.data?.description
          this.$toast.add({
            severity: 'error',
            summary,
            detail,
            life: 5000
          });
        })
    }
  }
}
</script>

<style scoped>

</style>
